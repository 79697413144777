@import 'src/client_customizations/scss/client_master.scss';

.homepage-banner {
  background-image: url('../../../client_customizations/assets/images/homepage_banner.svg');
  height: 38px;
  background-repeat: no-repeat;
    background-size: cover;
}

.homepage-jumbotron {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  row-gap: 91px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../client_customizations/assets/images/homeHeroImage.png');
  height: 554px;

  @media (max-width: 576px) {
      height: 154px;
    }

  img {
       width:100%;
       height:auto;
       opacity: 0;
  }

  .banner-subsection {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 17px;
    width: 100%;
    height: 134px;
    flex-shrink: 0;
    background-color: rgba(148, 140, 242, 0.5);
    padding: 47px;

    @media (max-width: 992px) {
      row-gap: 20px;
      flex-direction: column;
      padding: 30px;
      height: inherit;
    }

    .banner-subsection-text {
      color: #ffffff;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 128.571% */

      @media (max-width: 992px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .title-banner-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-banner { 
    color: #fff;
    text-align: center;
    font-family: "BentonSans";
    font-size: 120px;
    font-style: normal;
    font-weight: 500;
    line-height: 126px; /* 95% */
    text-transform: uppercase;
    display: flex;

    @media (max-width: 992px) {
      font-size: 32px;
      line-height: inherit;
    }
  }
  .subtitle-banner {
    color: #ffffff;
    font-weight: 400;
    font-family: "BentonSans";
    font-size: 28px;
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
        font-size: 16px;
        margin: -20px 60px 0px 100px;
      }

  }

  .title-banner-end {
    color: #184998;
  }
}

#why-choose-evs {
  padding: 0rem 1rem;
}
.big-promise-caption {
  top: 0;
  left: 0;
  position: absolute;
  padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
  background-color: #f3f3f3;
}

.homepage-titles-container {
  display: flex;
  row-gap: 17px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 766px) {
    gap: inherit;
    flex-direction: column;
  }
    
}

.max-width {
  max-width: 877px;
}

.HomepageSubTitle {
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 766px) {
    width: 95%;
    text-align: left;
  }
}

.primary {
  color: #2a2a2a;
  font-family: Futura PT;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 119.149% */
  text-transform: uppercase;
}

.secondary {
  color: #184998;
  font-weight: 500 !important;
}

.HomepageFaq {
  padding: 75px 0;
  display: flex;
  background-color: #f8f8f8;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem 17rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }

  .HomepageFaqTitle {
    width: 65%;
    display: flex;
    gap: 10px;

    .questions {
      color: black;
      font-family: Futura PT;
    }

    .answered {
      font-family: Futura PT;
      font-weight: 600;
    }

    @media (max-width: 766px) {
      gap: inherit;
      flex-direction: column;
    }
  }

  .HomepageFaqSubTitle {
    width: 65%;
    font-size: 20px;
    font-weight: 400;
    font-family: Futura PT;

    @media (max-width: 766px) {
      width: 95%;
      text-align: center;
    }
  }

  .MuiAccordion-root {
    width: 70% !important;
    background-color: #f8f8f8;
    box-shadow: inherit;
    border-radius: inherit !important;
    border-top: 1px solid #576116;
  }
}

.container-home {
  padding: 5rem 17rem;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-incentives {
  background-repeat: no-repeat;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  row-gap: 20px;
  padding: 50px 30px;
}

.homepage-incentives-title-container {
  display: flex;
  row-gap: 17px;
  max-width: 1100px;
  flex-direction: column;
  align-items: center;
    justify-content: center;
}

.homepage-incentives-title {
  display: flex;
  justify-content: center;
  color: #184998;
  font-family:  "BentonSans";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3em;
  text-transform: uppercase;
  align-items: center !important;
  text-align: center;

  .highline {
    color: #184998;
    font-weight: 400 !important;
  }
}

.homepage-incentives-title-sub {
  margin: 15px 0px 0px 0px !important;
  display: flex;
  justify-content: center;
  color: #2A2A2A;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-align: center !important;
    max-width: 800px;
}

.homepage-incentives-bar {
  background-color: rgba(221, 240, 252, 0.5);
  mix-blend-mode: screen;
  height: 112px;
  width: 100%;
}

.carousel-incentives-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap:10px;

  @media(max-width: 992px) {
       column-gap: 10px;
       row-gap: 10px;
  }
}
.incentives-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap:10px;
  flex-wrap: wrap;

  @media(max-width: 992px) {
       column-gap: 10px;
       row-gap: 10px;
  }
}

.homepage-home-charger-banner {
  background-color: rgba(65, 52, 130, 0.853);
  padding-bottom: 85px;
}

.homepage-home-charger-title-container {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  padding: 5.3rem 0rem 3.75rem 0rem;
  max-width: 1100px;
  margin:0 auto;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-home-charger-title {
  color: #ffffff;
  font-family: "BentonSans";
  font-size: 47px !important;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  max-width: 877px;

  .highline {
    color: #184998;
    font-family: "BentonSans";
    font-size: 47px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.homepage-home-charger-title-sub {
  font-family: "BentonSans";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.homepage-home-charger-get {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-map-section-container {
       padding:0;

       .home-map-section {
              max-width:1100px;
              padding:5rem 0;
              margin:0 auto;
              @media (max-width: 992px) {
                  margin: 2.5rem 2.5rem;
                }
       }
}
