.discover-section {
       padding:50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
  background-color: #F4F4F4;
  overflow: hidden;
}

.discover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  .title-head {
       color:black;
  }
}

.title-head {
  font-family: 'BentonSans';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3em;
  text-transform: uppercase;
  flex-wrap: nowrap !important;
}

.title-head-discover {
  color: #184998;
}

.title-sub {
  font-family: 'BentonSans';
  display: flex;
  justify-content: center;
  color: #2a2a2a;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-align: center !important;
  max-width: 800px;
}

.car-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:50px;
}

.car-list {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  max-width: 1180px;
  overflow: hidden;
  flex-wrap: nowrap;
}

.inner-car-slider {
       display: flex;
       gap: 20px;

       // @media(max-width:1150px) {
       //        flex-direction: column;
       // }
}

.flip {
  transform: scaleX(-1);
}