#EVAge {
    display: inline;

    .ev-filter-btn {

    height: 65px !important;
        text-transform: uppercase;
        font-size: 16px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 20px 40px;
        cursor: pointer;
        width: 50%;
        outline: none;
        margin-bottom: 10%;
    }

    .buttonActiveStyle {
        background-color: #184998;
        color: white;
    }

    .buttonInactiveStyle {
        color: #2A2A2A;
        background-color: white;
    }
}