@import 'src/client_customizations/scss/client_master.scss';

.IncentiveCard {
  margin-right: 1%;
  margin-bottom: 1%;
  border-radius: $IncentiveCard-card-border-radius;
  border: none;
  border-top: 8px solid #184998;
  box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.14);
  background-color: white;
  width: 255px;
  height: 369px ;

  display: flex;
  flex-direction: column;

  &:hover {
       text-decoration: none;
    p {
      text-decoration-color: #184998 !important;
    }
  }
}
.IncentiveCardBody {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  color: $IncentiveCard-card-body-color;
  border-radius: 0;

  .card-title {
    height: 55px;
    font-family: 'BentonSans' !important;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 16px;
  }

  .light {
    font-family: 'BentonSans', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    max-width: 80%;
    margin: 24px auto 0;
  }

  img {
    margin-top: -12px;
    margin-bottom: -16px;
    width: 60px;
  }
}
.IncentiveCardTop {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  color: $IncentiveCard-card-body-color;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 400;
  .card-title {
    margin-top: 1rem;
    color: $IncentiveCard-card-title-color;
  }
  span {
    font-family: 'BentonSans', Helvetica, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #2a2a2a;
  }
}
.hrContainer {
  margin-top: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #dadada;
}

.IncentiveCardBottom {
  transition: opacity 0.5s ease-in;
  color: #2A2A2A;
  font-size: 14px;
  font-weight: 400;
  min-height: 72px;
  padding: 1.5rem 1rem;
  @media (max-width: 992px) {
      margin: 2rem 2rem;
    }

    &.active {
       position: relative;
       height:100%;

       .incentive-card-bottom-content {
              height:85%;
              overflow-y: scroll;
           }

       .showBtn {
              position: absolute;
              bottom:0;
              left:0;
       }
    }
}
.displayNone {
  display: none !important;
}

.showBtn {
  width: 100%;
  text-align: center;
  border: none;
  background-color: transparent;

  .readFull {
    font-family: 'BentonSans' !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    color: #184998;
    background-color: #fff;
    border: 4px solid transparent;
    padding: 10px;
    text-transform: uppercase;
    &:hover {
      border: 4px solid #007FA4;
      color: #007FA4;
      padding: 10px;
      text-decoration: none;
    }
  }
}
.readLess {
  display: flex;
  justify-content: space-between;
}


@media (min-width: 768px) {
  .IncentiveCardBody {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
  }

 
}
