@import "src/client_customizations/scss/client_master.scss"; 

.PricePanels {
	background-color: $PricePanels-background-color;
	border: $PricePanels-border;

       @media(max-width:768px) {
              margin-top:50px;
       }

	.Nav {
		border-bottom: $PricePanels-Nav-border;
		margin: 0;
		padding: 0;
	}
	li {
		a {
			font-weight: $PricePanels-li-a-font-weight;
			font-size: $PricePanels-li-a-font-size;
			color: $PricePanels-li-a-font-color;
			cursor: pointer;
			margin-top: 16px;
			margin-bottom: 10px;
			&:hover {
				text-decoration: none;
			}
		}
		a.active {
			span {
				padding-bottom: 16px;
				border-bottom: $PricePanels-li-a-span-border_active;
			}
		}
	}
	.nav-pills {
		.nav-link.active {
			background-color: $PricePanels-nav-pills-nav-link-background-color_active;
			color: $PricePanels-nav-pills-nav-link-font-color_active;
		}
	}
	.tab-pane {
		padding: 0;
		.PanelItem {
			border-top: $PricePanels-tab-pane-PanelItem-border;
			padding: 15px;
		}
		.PanelItemTop {
			display: inline-block;
			text-align: center !important;
		}
	}
}
.MatchScoreBadge {
	text-align: center;
	background-color: $MatchScoreBadge-background-color;
	padding: 5px;
	border: $MatchScoreBadge-border;
	margin-top: 15px;
}
