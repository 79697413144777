.faqContainerPage {
  margin-top: 20px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
      margin: 2.5rem 2.5rem;
    }

  h2 {
    font-weight: bold;
    color: #403382;
    text-transform: uppercase;
    font-size: 20px !important;
    margin: 50px 0 10px;

    &:first-child {
      margin-top: 0;
    }
        
  }

  .contentShow {
    opacity: 1;
    height: 100%;
    transition: all 0.8s linear;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
  }

  .contentHide .collapsibleSectionBodyContent {
    height: 0;
    opacity: 0.01;
    transition: opacity 15ms linear;
    justify-content: center;
    display: none;
  }

  .iconClose {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .iconOpen {
    cursor: pointer;
    transform: rotate(180deg);
  }

  .CollapsibleSectionContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .collapsibleSection {
    box-shadow: 0px 20px 41px 0px #0000001a;
    padding: 25px;
    background-color: #ffffff;
    width: 100%;

    .collapsibleSectionHead {
      align-items: center;
      display: flex;
      row-gap: 30px;
      flex-direction: row;
      justify-content: space-between;

      .collapsibleSectionHeadLine {
        font-family: Roboto;
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        font-weight: 300;
      }
    }

    .collapsibleSectionBodyContent {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.faqContainerHomePage {
  border-top: 1px solid #576116;
  margin-top: 30px;
  margin-bottom: 42px;
  width: 100%;

  .contentShow {
    opacity: 1;
    height: 100%;
    transition: all 0.8s linear;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
  }

  .contentHide .collapsibleSectionBodyContent {
    height: 0;
    opacity: 0.01;
    transition: opacity 15ms linear;
    justify-content: center;
    display: none;
  }

  .iconClose {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .iconOpen {
    cursor: pointer;
    transform: rotate(180deg);
  }

  .collapsibleSection {
    padding: 25px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #576116;

    .collapsibleSectionHead {
      align-items: center;
      display: flex;
      row-gap: 30px;
      flex-direction: row;
      justify-content: space-between;

      .collapsibleSectionHeadLine {
        font-family: Roboto;
        font-style: normal;
        font-size: 28px;
        line-height: 36px;
        font-weight: 300;
      }
    }

    .collapsibleSectionBodyContent {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
