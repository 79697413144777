.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

.carDetailContainer {
       padding:50px 15px 30px;
  background: #FFFFFF;

  @media(max-width:768px) {
       padding: 25px 0px 30px;
  }
}

.carDetailsTable {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  color:#3D3935;

  @media(max-width:768px) {
       row-gap: 10px;

       .carDetailsFooter {
              margin-top: 1rem !important;
       }
  }

    @media (min-width: 576px) {
              .carDetailsFooter {
                     margin-top: 8rem;
              }
       }
}
