@import 'src/client_customizations/scss/client_master.scss';

.CompareVehicles {
  background-color: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 40px;


  .compared-vehicle-container {
    background-color: #f4f4f4 !important;
    padding-top: 20px;
    overflow: hidden;
  }

  .single-vehicle {
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
       font-size:20px;
    }

    h4 {
       font-size:16px;
    }
  }

  .BreakevenChart-section-container {
    display: flex;
    padding: 2rem 25rem;
    justify-content: flex-end;

    .BreakevenChart-container {
      width: 825px;
    }
  }
}
