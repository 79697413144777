@import 'src/client_customizations/scss/client_master.scss';

.link-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
@media (max-width: 576px) {
  .link-card {
    width: 150px !important;
  }
}

.link-card {
  display: flex;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  .evc-card-image-hover {
      display: none;
    }

  img {
      margin: 5px 0px;
  }
  img:hover {
    fill: red !important;
  }
    
  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  &:hover {
    .evc-card-image {
        display: none;
      }
      .evc-card-image-hover {
        display: block;
      }
    
    background-color: #403382;
    p {
      text-decoration: none;
    }
  }
  .evc-card {
    display: flex;
    align-items: center;
    border: none;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $LinkCard-evc-card-background-color;
    padding: 2.5rem;

    @media(max-width:991px) {
       padding:20px 0 20px;
       }

    p {
      font-size: 0.7rem;
    }
    &:hover {
      background-color: $LinkCard-card-hover-body-color;
      box-shadow: 0px 20px 41px 0px #0000001A;
      height: 115%;
      margin-top: -7%;
    }
    .btn-homepage {
      color: #2a2a2a;
      text-align: center;
      font-family: 'BentonSans' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3em; 
      text-transform: capitalize;

      @media(max-width:991px) {
              font-size:16px;
      }
    }
  }
}
