.MatchScoreOptions {
  .h2 {
    font-family: "BentonSans", Helvetica, sans-serif;
    color: #2A2A2A;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}